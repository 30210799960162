import React from 'react';
import PropTypes from 'prop-types';
import {zeroPad} from "react-countdown";
import classNames from 'classnames';

function CountdownLayoutBelowEvent(props) {

    if (props.completed) {
      return (
        <div>
          <h2 className={classNames('fl pmd-13')}>{props.i18n('ticketSale.select.validationMessages.buyNow')}</h2>
          <a onClick={function() { window.location.reload(true)}}>
            <div className={classNames('bg--secondary circle click-area arrow-right fr')}></div>
          </a>
        </div>
      )
    }
    else {
      return (
        <div className={classNames("rect--responsive cf phl pbl mw-col bg-white ticket-border ptl")}>
          <div>
            <span className={classNames("f-18 db")}>
              <div id="getTickets" hidden>
                <h2 className={classNames('fl pmd-13')}>{props.i18n('ticketSale.select.validationMessages.buyNow')}</h2>
                <a onClick={function() { window.location.reload(true)}}>
                  <div className={classNames('bg--secondary circle click-area arrow-right fr')}></div>
                </a>
              </div>
              <div id="SECountdown">
                <div className={classNames("fl w-40 f-16 pmd-13")}>{props.i18n('ticketSale.countdown.saleStart')}</div>
                  <div className={classNames("fr w-60 tc")}>
                    <div className={classNames("important-flex")}>
                      <div>
                        <h1 className={classNames("w-10 f0")} id="days">{zeroPad(props.days)}</h1>
                      </div>
                        <h1 className={classNames("w-10 pmu-7")}> : </h1>
                      <div>
                        <h1 className={classNames("w-10 f0")} id="hours">{zeroPad(props.hours)}</h1>
                      </div>
                        <h1 className={classNames("w-10 pmu-7")}> : </h1>
                      <div>
                        <h1 className={classNames("w-10 f0")} id={'minutes'}>{zeroPad(props.minutes)}</h1>
                      </div>
                        <h1 className={classNames("w-10 pmu-7")}> : </h1>
                      <div>
                        <h1 className={classNames("w-10 f0")} id={'seconds'}>{zeroPad(props.seconds)}</h1>
                      </div>
                    </div>
                    <div className="dt">
                      <div className={classNames("f3 dtc sed")}>{props.i18n('ticketSale.countdown.days')}</div>
                      <div className={classNames("f3 dtc seh")}>{props.i18n('ticketSale.countdown.hours')}</div>
                      <div className={classNames("f3 dtc sem")}>{props.i18n('ticketSale.countdown.minutes')}</div>
                      <div className={classNames("f3 dtc ses")}>{props.i18n('ticketSale.countdown.seconds')}</div>
                    </div>
                  </div>
              </div>
            </span>
          </div>
        </div>
       )
    }
}

CountdownLayoutBelowEvent.propTypes = {
    completed: PropTypes.bool.isRequired,
    days: PropTypes.number.isRequired,
    hours: PropTypes.number.isRequired,
    minutes: PropTypes.number.isRequired,
    seconds: PropTypes.number.isRequired,
    i18n: PropTypes.func.isRequired
};

export default CountdownLayoutBelowEvent;
