import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';

function InfoPanel(props) {
  return (
    <div
      className={classNames('info-panel animated bg-white phl', { hidden: props.hidden })}
      itemScope itemType="http://schema.org/Event">
      {props.children}
    </div>
  );
}

InfoPanel.propTypes = {
  hidden: PropTypes.bool.isRequired,
};

function dateFormat(date, time) {
  if (date && time) {
    return date + ' / ' + time;
  } else if (time) {
    return time;
  } else if (date) {
    return date;
  } else {
    return '';
  }
}

export { InfoPanel, dateFormat };
