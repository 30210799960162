/* global i18n: false */
import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';

export function EventDay(props) {
  return <span className={classNames(props.size, 'lh', 'fw7')}>{props.children}</span>;
}

EventDay.propTypes = {
  size: PropTypes.string.isRequired,
};

export function EventName(props) {
  const eventClasses = classNames(['f-18 lh-title',
    { 'oh mts': !props.truncate },
    { 'truncate': props.truncate },
    { 'w-17e': props.narrow },
  ]);
  return <h2 className={eventClasses}>{props.children}</h2>;
}

EventName.propTypes = {
  truncate: PropTypes.bool.isRequired,
  narrow: PropTypes.bool,
};

export function TextColorSwitch(props) {
  var currentColor = props.isCollapsed ? props.primaryBackgroundColor : props.secondaryBackgroundColor;
  if (currentColor.startsWith('#')) {
    currentColor = currentColor.replace('#', '');
  }
  const red = parseInt(currentColor.substr(0, 2), 16);
  const green = parseInt(currentColor.substr(2, 2), 16);
  const blue = parseInt(currentColor.substr(4, 2), 16);
  const brightness = (( 299 * red + 587 * green + 144 * blue ) / 2550);

  const color = brightness > 60 ? 'color-black' : 'color-white'
  return <div className={(color)}>{props.children}</div>;
}

TextColorSwitch.propTypes = {
  primaryBackgroundColor: PropTypes.string.isRequired,
  secondaryBackgroundColor: PropTypes.string.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
};

export function InfoBadge(props) {
  return (
    <div
      className={classNames('click-area circle-text circle event-info info-button fr f2 animated lh', {
        'default-pointer': props.saleStartMode === "SOON" && props.isBeforeSaleStart,
        fadeIn: props.active,
        active: props.active,
        hidden: props.hidden
      })}
      onClick={props.onClick}
    >
      {props.saleStartMode === "SOON" ? (
        <div className="fw7 f-16">{props.children}</div>
      ) : (
        <a className="fw7 f2">
          {props.children}
        </a>
      )}
    </div>
  );
}

export function EventTime(props) {
  return (
    <span className={classNames('lh kilo fr fw7', { hidden: props.hidden })}>
      {props.children}
      <span className={'f-18 lh mls'}>{i18n('web.ticketselection.hours')}</span>
    </span>
  );
}
