/* global i18n : false*/
import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';

// customized version of github.com/uken/react-countdown-timer
class Timer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeRemaining: this.props.initialTimeRemaining,
      timeoutId: null,
      prevTime: null,
    };
    this.tick = this.tick.bind(this);
    this.getFormattedTime = this.getFormattedTime.bind(this);
  }
  componentDidMount() {
    this.tick();
  }

  componentWillUnmount() {
    clearTimeout(this.state.timeoutId);
  }

  tick() {
    const currentTime = Date.now();
    const dt = this.state.prevTime ? (currentTime - this.state.prevTime) : 0;
    const interval = this.props.interval;

        // correct for small variations in actual timeout time
    const timeRemainingInInterval = (interval - (dt % interval));
    let timeout = timeRemainingInInterval;

    if (timeRemainingInInterval < (interval / 2.0)) {
      timeout += interval;
    }

    const timeRemaining = Math.max(this.state.timeRemaining - dt, 0);
    const countdownComplete = (this.state.prevTime && timeRemaining <= 0);
    if (this.state.timeoutId) {
      clearTimeout(this.state.timeoutId);
    }
    this.setState({
      timeoutId: countdownComplete ? null : setTimeout(this.tick, timeout),
      prevTime: currentTime,
      timeRemaining: timeRemaining,
    });
    if (countdownComplete) {
      if (this.props.completeCallback) { this.props.completeCallback(); }
      return;
    }

    if (this.props.tickCallback) {
      this.props.tickCallback(timeRemaining);
    }
  }

  getFormattedTime(milliseconds) {
    if (this.props.formatFunc) {
      return this.props.formatFunc(milliseconds);
    }

    const totalSeconds = Math.round(milliseconds / 1000);

    let seconds = parseInt(totalSeconds % 60, 10);
    let minutes = parseInt(totalSeconds / 60, 10) % 60;

    seconds = seconds < 10 ? '0' + seconds : seconds;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    if (totalSeconds > 0) {
      return minutes + ':' + seconds;
    } else {
      return 0;
    }
  }

  render() {
    const timeRemaining = this.state.timeRemaining;

    return (
      <div className={classNames('timer bg--info-blue pvm phl f3', { hidden: ! (this.state.timeRemaining > 0) })} >
          <span className="fl">{ this.props.label || i18n('ticketSale.preview.timer.notification')}</span>
          <span className="fr">{this.getFormattedTime(timeRemaining)}</span>
      </div>
    );
  }
}

Timer.displayName = 'CountdownTimer';
Timer.propTypes = {
  initialTimeRemaining: PropTypes.number.isRequired,
  interval: PropTypes.number,
  formatFunc: PropTypes.func,
  tickCallback: PropTypes.func,
  completeCallback: PropTypes.func,
  label: PropTypes.string,
};
Timer.defaultProps = {
  interval: 1000,
  formatFunc: null,
  tickCallback: null,
  completeCallback: null,
};
export default Timer;
