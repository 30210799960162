import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

function NoTicketsSelected(props) {
  return (
    <div className={ classNames({ hidden: props.hidden, ticketList: !props.minalModeEnabled }, 'fl mw-col bg-white fadeIn animated') }
         style={ props.largeScreen ? { marginTop: props.orderPreviewTopOffset + 'px' } : {} }>
        <p className="man phl pvl rect--responsive f3">{ props.i18n('ticketSale.select.emptyBasket')}</p>
      {props.children}
    </div>
  );
}

NoTicketsSelected.propTypes = {
  hidden: PropTypes.bool,
  minalModeEnabled: PropTypes.bool,
  largeScreen: PropTypes.bool,
  orderPreviewTopOffset: PropTypes.number,
  children: PropTypes.node,
  i18n: PropTypes.func.isRequired,
};

NoTicketsSelected.defaultProps = {
};

export default NoTicketsSelected;
