import PropTypes from 'prop-types';
import React from 'react';
import { formatPrice } from './utils.js';
import classNames from 'classnames';

function PricePanel(props) {
  if (props.totalPrice > 0) {
    return (
      <div className="rect--responsive mw-col phl pvl bg--lightest-silver flex flex-wrap justify-between content-around ticket-border-white">
        <h2 className={classNames({'hidden': props.servicePrice === 0}, 'f-18')} style={{ width: '60%' }}>{props.servicePriceLegend}</h2>
        <h2 className={classNames("tr", {'hidden': props.servicePrice === 0}, 'f-18')} style={{ width: '40%' }}>{formatPrice(props.servicePrice)}</h2>
        <h2 className={classNames({'hidden': props.orderFee === 0}, 'f-18')} style={{ width: '60%' }}>{props.orderFeeLegend}</h2>
        <h2 className={classNames("tr", {'hidden': props.orderFee === 0}, 'f-18')} style={{ width: '40%' }}>{formatPrice(props.orderFee)}</h2>
        <h2 className={classNames("fw7", 'f-18')} style={{ width: '60%' }}>{props.totalPriceLegend}</h2>
        <h2 className={classNames("fw7 tr", 'f-18')} style={{ width: '40%' }}>{formatPrice(props.totalPrice)}</h2>
      </div>
    );
  } else {
    return (
      <div className="rect--responsive mw-col phl flex flex-wrap justify-between content-center bg--lightest-silver ticket-border-white">
        <h2 className="f-18 fw7" style={{ width: '60%' }}>{props.totalPriceLegend}</h2>
        <h2 className="f-18 fw7 tr" style={{ width: '40%' }}>{formatPrice(props.totalPrice)}</h2>
      </div>
    );
  }
}

PricePanel.propTypes = {
  servicePrice: PropTypes.number,
  orderFee: PropTypes.number,
  totalPrice: PropTypes.number,
  totalPriceLegend: PropTypes.string,
  servicePriceLegend: PropTypes.string,
  orderFeeLegend: PropTypes.string,
};

export default PricePanel;
