import PropTypes from 'prop-types';
import React from 'react';
import { getContrastingColor } from './utils.js';
import classNames from 'classnames';

function ContinueShoppingButton(props) {
  const labelColor = props.colorDark ? getContrastingColor(props.colorDark) : "";
  return (
    <div className="bg--primary-dark rect--responsive flex content-center justify-between items-center pvl phl">
      <button className="bg-white circle click-area arrow-left bn" onClick={props.onClick} id="backButton">
      </button>
      <label className={classNames('f0 clickable ' + labelColor)} htmlFor="backButton">
        {props.message}
      </label>
    </div>
  );
}

ContinueShoppingButton.propTypes = {
  onClick: PropTypes.func,
  message: PropTypes.string,
};

export default ContinueShoppingButton;
