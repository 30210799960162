import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';
import { formatPrice, getContrastingColor } from './utils.js';
import Rectangle from './Rectangle.jsx';

function Ticket(props) {
  const bgColor = props.highlight ? 'bg--secondary' : 'bg-white';
  const txtColor = props.highlight ? getContrastingColor(props.highlight ? props.colorSecondary : "#FFFFFF") : "";
  const price = formatPrice(props.price);
  const addTicketColor = props.colorSecondary ? getContrastingColor(props.highlight ? "#FFFFFF" : props.colorSecondary) : "";
  const addTicketButtonClasses = classNames('fr f2 circle circle-text click-area ' + (props.highlight ? 'bg-white' : ' bg--secondary'),
      { hidden: props.soldOut });
  const borderColorToAdd = props.highlight ? getContrastingColor(props.highlight ? props.colorSecondary : "#FFFFFF") : "";
  const borderColor = borderColorToAdd === 'color-white' ? 'locked-ticket-border-white' : 'locked-ticket-border-black'

  return (
    <Rectangle
        color={bgColor}
        top
        topCSSBorder={ props.showTopCssBorder ? 'ticket-border' : borderColor }
        bottomBorderColor={props.bottomBorderColor}
        showBottomSVGBorder={props.showSVGBorder}
        classNames={props.showAllReservedText ? ['h-fit-content'] : []}
        showAllReservedText={props.showAllReservedText}>
      <div className={classNames({ 'mw-75': !props.soldOut, 'mw-100': props.soldOut }, 'fl cf')}>
        <div className={classNames("f-18 truncate " + txtColor )} data-testid="ticket-name">{props.name}</div>
        {props.showPrice && <div className={classNames("f2 mrm fw7 " + txtColor)} data-testid="ticket-price">{price}</div>}
        <p className={classNames("f3 man " + txtColor)}>{props.infoText}</p>
      </div>
      <div className={addTicketButtonClasses} onClick={props.addTicket} data-test="select-ticket">
      <a className={classNames("fw7 f-40 tcn " + addTicketColor, { hidden : props.spinnerTicketGroupIds.indexOf(props.currentTicketString) !== -1})}>
        {props.i18n('web.ticketselection.addTicket')}
      </a>
      <i className={classNames("fa fa-spinner fa-2x fa-spin-pulse fl", { hidden : props.spinnerTicketGroupIds.indexOf(props.currentTicketString) === -1})} aria-hidden="true" />
      </div>
    </Rectangle>
  );
}

Ticket.propTypes = {
  addTicket: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  i18n: PropTypes.func.isRequired,
  infoText: PropTypes.string,
  name: PropTypes.string,
  currentTicketString: PropTypes.string,
  price: PropTypes.number.isRequired,
  showPrice: PropTypes.bool.isRequired,
  highlight: PropTypes.bool,
  soldOut: PropTypes.bool,
  bottomBorderColor: PropTypes.string,
  colorSecondary: PropTypes.string,
  showSVGBorder: PropTypes.bool,
  showTopCssBorder: PropTypes.bool,
  spinnerTicketGroupIds: PropTypes.array,
  showAllReservedText: PropTypes.bool
};

Ticket.defaultProps = {
  bottomBorderColor: '#CCCCCC',
  showBottomBorder: false,
};

export default Ticket;
