/* global currentOrganizationFee */
import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import classNames from 'classnames';
import moment from 'moment';
import Badge from './Badge.jsx';
import Border from './Border.jsx';
import {Collapse} from 'react-collapse';
import {dateFormat, InfoPanel} from './InfoPanel.jsx';
import Ticket from './Ticket.jsx';
import LockedField from './LockedField.jsx';
import OrderFee from './OrderFee.jsx';
import Cookies from 'js-cookie';
import {
  EventDay,
  EventName,
  EventTime,
  InfoBadge,
  TextColorSwitch,
} from './EventHeading.jsx';
import MediaObject from './MediaObject.jsx';
import Countdown from "react-countdown";
import CountdownLayout from "./CountdownLayout.jsx";
import CountdownLayoutBelowEvent from "./CountdownLayoutBelowEvent.jsx";
import InfoLayoutBelowEvent from "./InfoLayoutBelowEvent.jsx";

function Event(props) {
  function checkUnlockCode() {
    const unlockForm = document.getElementById('unlockCodeForm');
    unlockForm.elements.targetLocation.value = unlockForm.elements.targetLocation.value + "#event-" + props.eventId
    unlockForm.elements.unlockCode.value = document.getElementById(
      'checkLocked' + props.eventId
    ).value;
    Cookies.set('lockedFlag', props.eventId);
    unlockForm.elements.eventIds.value = props.allEventIds.join(',');
    unlockForm.submit();
  }

  function allReserved(ticketGroup) {
    return !ticketGroup.soldOut && ticketGroup.amountLeftWebEstimate === '0';
  }

  function getInfoText(ticketGroup) {
    if (ticketGroup.soldOut) {
      return props.i18n('ticketSale.select.validationMessages.soldOut');
    } else if (allReserved(ticketGroup)) {
      return props.i18n('ticketSale.select.validationMessages.allReserved');
    } else {
      if (ticketGroup.plentyLeftWeb) {
        return '';
      } else if (ticketGroup.amountLeftWebEstimate === '1') {
        return props.i18n('ticketSale.notify.ticketLeft');
      } else {
        return props.i18n('ticketSale.notify.ticketsLeft', ticketGroup.amountLeftWebEstimate);
      }
    }
  }


  const lockedTickets = [];
  const unlockField = [];
  const normalTickets = [];
  let count = 0;
  const shouldShowEndDay =
    props.programEndDayFormatted !== '' &&
    props.programEndDayFormatted !== props.programStartDayFormatted;

  let isSoldOut = props.ticketGroups.length > 0;

  props.ticketGroups.forEach(function renderTicket(ticketGroup) {
    count++;
    function addTicket() {
      props.reserveTicket(ticketGroup, props.eventId);
      // on mobile eventInfo is inline, no need to hide it as it doesn't interfere with the shopping cart column
      if (props.eventInfoShown && props.largeScreen) {
        props.toggleEventInfo();
      }
    }
    const ticket = (
      <Ticket
        i18n={props.i18n}
        spinnerTicketGroupIds={props.spinnerTicketGroupIds}
        eventId={props.eventId}
        addTicket={addTicket}
        infoText={getInfoText(ticketGroup)}
        name={ticketGroup.ticketType.name}
        price={ticketGroup.price + ticketGroup.webFee * ticketGroup.ticketType.weight}
        showPrice={!allReserved(ticketGroup)}
        highlight={ticketGroup.ticketType.unlockCodeRequired}
        soldOut={ticketGroup.soldOut || allReserved(ticketGroup)}
        showTopCssBorder={!ticketGroup.ticketType.unlockCodeRequired}
        key={ticketGroup.id}
        currentTicketString={ticketGroup.id.toString()}
        colorSecondary={props.colorSecondary}
        bottomBorderColor={
          count < props.ticketGroups.length - 1 ? '#CCCCCC' : props.bottomBorderColor
        }
        showSVGBorder={
          count === props.ticketGroups.length &&
          !props.isLastEvent &&
          !props.showLocked &&
          !props.currentOrganizationFee
        }
        showAllReservedText={getInfoText(ticketGroup) === props.i18n('ticketSale.select.validationMessages.allReserved')}
      />
    );

    if (!props.isBeforeSaleStart) {
      if (!ticketGroup.ticketType.unlockCodeRequired) {
        normalTickets.push(ticket);
      } else {
        lockedTickets.push(ticket);
      }
    }

    if (!ticketGroup.soldOut) {
      isSoldOut = false;
    }
  });

  if (props.showLocked) {
    const field = (
      <LockedField
        key={props.eventId}
        eventId={props.eventId}
        show={props.showLocked}
        checkCode={checkUnlockCode}
        showBottomBorder={!lockedTickets && !props.collapsed && props.index > 0}
        bottomBorderColor={props.bottomBorderColor}
        hasLockedTicket={lockedTickets.length > 0}
        i18n={props.i18n}
      />
    );
    unlockField.push(field);
  }

  const shouldShowOrderFee = currentOrganizationFee !== 0 && !isSoldOut;
  const shouldShowTime = props.ticketsNumber === 0 && (!isSoldOut || (props.showLocked && !props.ticketGroups.length)) && !props.isBeforeSaleStart;

  const programStartDateAndTimeString = props.programStartDateString + " " + props.programStartTimeString
  const programStartDateAndTime = moment(programStartDateAndTimeString, "EEEE_D_MMMM_YYYY HH:mm").format("DD_MM_YYY HH:mm")

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    return <CountdownLayout
      isBeforeSaleStart={props.isBeforeSaleStart}
      color={"white"}
      soldOut={isSoldOut}
      completed={completed}
      days={days}
      hours={hours}
      minutes={minutes}
      seconds={seconds}
      i18n={props.i18n}
    />
  };

  return (
    <div className={classNames('pos-rel', { hidden: !props.show })}>
      <div
        className={classNames('rect--responsive cf pvl phl event background-switch ptl pos-rel', {
          active: !props.collapsed,
        })}
        style={{ backgroundColor: props.backgroundColor }}
        data-event-id={props.eventId}
        id={"event-" + props.eventId}
      >
        <Border
          color={props.topBorderColor}
          show={!props.collapsed && props.index > 0}
          top={'-1.8rem'}
          gap={12}
        />

        <div
          className={classNames('fl big-target', {
            'mw-60':
                (props.collapsed && !isSoldOut) &&
                (props.saleStartMode === "COUNTDOWN" && props.isBeforeSaleStart),
            'mw-75':
              (props.displayInfoButton && !props.collapsed) ||
              isSoldOut ||
              (props.ticketsNumber !== 0 && props.collapsed) ||
              (props.saleStartMode === "SOON" && props.isBeforeSaleStart),
            'w-95':
              (!props.displayInfoButton || props.collapsed) &&
              (props.ticketsNumber !== 0 ? !props.collapsed : true),
            'w-75': !(
              (!props.displayInfoButton || props.collapsed || isSoldOut) &&
              (props.ticketsNumber !== 0 ? !props.collapsed : true)
            ),
            'default-pointer': props.isBeforeSaleStart
          })}
          role="button"
          onClick={props.isBeforeSaleStart ? function (){} : function toggleEvent() {
            props.toggleEvent(props.eventId);
          }}
        >
          <TextColorSwitch
            primaryBackgroundColor={props.backgroundColor}
            secondaryBackgroundColor={props.colorSecondary}
            isCollapsed={props.collapsed}>
            {props.displayEventNamesFirst ? (
              <Fragment>
                <EventDay size="kilo">{props.programStartDayFormatted}</EventDay>
                <EventDay size="f-18">{' '}{props.programStartDayNameShort}</EventDay>
                {shouldShowEndDay ? (
                <span>
                    <EventDay size="kilo">{' - ' + props.programEndDayFormatted}</EventDay>
                    <EventDay size="f2">{' '}{props.programEndDayNameShort}</EventDay>
                  </span> ) : null}
                <EventTime
                  hidden={(!props.collapsed && props.displayInfoButton) || !shouldShowTime}
                >
                  {props.programStartTimeString}
                </EventTime>
                <EventName truncate={true}>{props.title}</EventName>
              </Fragment>
            ) : (
              <Fragment>
                <EventDay size={shouldShowEndDay ? 'kilo' : 'mega'}>
                  {props.programStartDayFormatted}
                </EventDay>
                <EventDay size="f2">{' '}{props.programStartDayNameShort}</EventDay>
                <span className={classNames({ hidden: !shouldShowEndDay })}>
                  <EventDay size="kilo">{' - ' + props.programEndDayFormatted}</EventDay>
                  <EventDay size="f2">{' '}{props.programEndDayNameShort}</EventDay>
                </span>
                <EventName truncate={true} narrow={props.ticketsNumber > 0}>{props.title}</EventName>
              </Fragment>
            )}
          </TextColorSwitch>
        </div>
        <Badge
          number={props.ticketsNumber}
          show={props.collapsed && !isSoldOut && !props.isBeforeSaleStart}
          secondaryColor={props.colorSecondary}/>
        <InfoBadge
          active={props.displayInfoButton}
          hidden={props.collapsed}
          onClick={function toggleEventInfo() {
            props.toggleEventInfo(props.eventId);
          }}
          saleStartMode={props.saleStartMode}
          isBeforeSaleStart={props.isBeforeSaleStart}
        >
          Info
        </InfoBadge>
        <InfoBadge
          active={props.collapsed}
          hidden={!isSoldOut || props.ticketsNumber !== 0 || props.showLocked}
          onClick={function toggleEventInfo() {
            props.toggleEventInfo(props.eventId);
          }}
          saleStartMode={props.saleStartMode}
          isBeforeSaleStart={props.isBeforeSaleStart}
        >
          Sold out
        </InfoBadge>
        <InfoBadge
          active={props.collapsed && props.saleStartMode === "SOON" && props.isBeforeSaleStart}
          hidden={isSoldOut}
          saleStartMode={props.saleStartMode}
          isBeforeSaleStart={props.isBeforeSaleStart}
        >
          On<br/>sale soon
        </InfoBadge>
        {props.saleStartMode === "COUNTDOWN" && props.isBeforeSaleStart &&
        !props.eventInfoShown && props.collapsed && (
          <Countdown
            date={props.firstSaleDate}
            renderer={renderer}
          />
        )}
      </div>
      {props.saleStartMode === "COUNTDOWN" && props.isBeforeSaleStart &&
       !props.collapsed && (
        <CountdownLayoutBelowEvent
          date={props.firstSaleDate}
          isBeforeSaleStart={props.isBeforeSaleStart}
          color={"black"}
          soldOut={props.isSoldOut}
          completed={props.completed}
          saleStartMode={props.saleStartMode}
          days={props.days}
          hours={props.hours}
          minutes={props.minutes}
          seconds={props.seconds}
          i18n={props.i18n}/>
      )}
      {(props.saleStartMode === "NOT" || props.saleStartMode === "SOON") && props.isBeforeSaleStart &&
       !props.collapsed && (
        <InfoLayoutBelowEvent
          saleStartMode={props.saleStartMode}
          i18n={props.i18n}
        />
      )}
      <InfoPanel hidden={!props.eventInfoShown}>
        <MediaObject
          {...props.coverMedia}
          shouldLoadMedia={props.eventInfoShown}
          title={props.bodyTitle}
        />
        <h2 className="f-18 mtm fw7 wwbw" itemProp="name">
          {props.bodyTitle}
        </h2>
        <h3 className="fw7" itemProp="description">{props.subtitle}</h3>
        <div>
          <meta itemProp="startDate" content={programStartDateAndTime} />
          {dateFormat(props.programStartDateString, props.programStartTimeString)}
          {props.isEventEndTimeShown ? 
            (
              <span>
                {' - '}
                {(props.programStartDateString !== props.programEndDateString && props.programEndDateString !== "")
                  ? dateFormat(props.programEndDateString, props.programEndTimeString)
                  : dateFormat(props.programEndTimeString)}
              </span>
            ) : ""
          }
        </div>
        <div className="pbl" dangerouslySetInnerHTML={{ __html: props.description }} />
        <div
          className="mvm"
          itemProp="location"
          itemScope
          itemType="https://schema.org/Place"
        >
          <div itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
            <h3 className="fw7">{props.i18n('webticket.eventinfo.location')}</h3>
            <div itemProp="name">{props.locationDetails.name}</div>
            <div itemProp="streetAddress">{props.locationDetails.street}</div>
            <div>
              <span itemProp="postalCode">{props.locationDetails.zipcode}&nbsp;</span>
              <span itemProp="addressLocality">{props.locationDetails.city}</span>
            </div>
          </div>
        </div>
      </InfoPanel>
      <Collapse isOpened={!props.collapsed}>
        {normalTickets}
        {unlockField}
        {lockedTickets}
        {shouldShowOrderFee && <OrderFee orderFee={props.currentOrganizationFee} />}
      </Collapse>
    </div>
  );
}

Event.propTypes = {
  index: PropTypes.number,
  eventId: PropTypes.number.isRequired,
  allEventIds: PropTypes.array,
  displayEventNamesFirst: PropTypes.bool,
  currentOrganizationFee: PropTypes.number,

  programStartTimeString: PropTypes.string,
  programStartDateString: PropTypes.string,
  programStartDayFormatted: PropTypes.string,
  programStartDayNameShort: PropTypes.string,

  programEndTimeString: PropTypes.string,
  programEndDateString: PropTypes.string,
  programEndDayFormatted: PropTypes.string,
  programEndDayNameShort: PropTypes.string,

  firstSaleDate: PropTypes.number,
  isBeforeSaleStart: PropTypes.bool,
  saleStartMode: PropTypes.string.isRequired,
  isLastEvent: PropTypes.bool,

  spinnerTicketGroupIds: PropTypes.array,
  ticketGroups: PropTypes.array.isRequired,
  ticketsNumber: PropTypes.number.isRequired,
  reserveTicket: PropTypes.func.isRequired,

  backgroundColor: PropTypes.string,
  topBorderColor: PropTypes.string,
  bottomBorderColor: PropTypes.string,
  colorSecondary: PropTypes.string,

  collapsed: PropTypes.bool.isRequired,
  show: PropTypes.bool,
  showLocked: PropTypes.bool,
  toggleEvent: PropTypes.func.isRequired,
  toggleEventInfo: PropTypes.func.isRequired,
  landingPageIsCalendar: PropTypes.bool,
  eventInfoShown: PropTypes.bool,
  displayInfoButton: PropTypes.bool,
  isEventEndTimeShown: PropTypes.bool,

  title: PropTypes.string,
  bodyTitle: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  locationDetails: PropTypes.object,
  coverMedia: PropTypes.object,
  largeScreen: PropTypes.bool,
  i18n: PropTypes.func,
};

export default Event;
