import PropTypes from 'prop-types';
import React from 'react';
import { getContrastingColor } from './utils.js';
import classNames from 'classnames';

function CheckoutButton(props) {
  const labelColor = props.colorSecondary ? getContrastingColor(props.colorSecondary) : "";

  return (
    <div className="bg--secondary rect--responsive flex content-center justify-between items-center pvl phl">
      <label className={classNames('f0 clickable ' + labelColor)} htmlFor="goToNextScreen">{props.message}</label>
      <button data-test="checkout" className={classNames('bg-white circle bn click-area', {'submitted': props.submitted, 'arrow-right': !props.submitted})}
              id="goToNextScreen"
              onClick={props.onClick}>
      </button>
    </div>

  );
}

CheckoutButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  submitted: PropTypes.bool,
  message: PropTypes.string,
  colorSecondary: PropTypes.string,
};

export default CheckoutButton;
