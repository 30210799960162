import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function InfoLayoutBelowEvent(props) {
  return (
    <div className={classNames("rect--responsive cf phl pbl mw-col bg-white ticket-border ptl")}>
      <div>
        <span className={classNames("f-18 db")}>
          {props.saleStartMode === "NOT" && (
            props.i18n('ticketSale.select.validationMessages.notAvailable')
          )}

          {props.saleStartMode === "SOON" && (
            <h1>{props.i18n('ticketSale.select.validationMessages.soonAvailable')}</h1>
          )}
        </span>
      </div>
    </div>
    )
}

InfoLayoutBelowEvent.propTypes = {
    saleStartMode: PropTypes.string.isRequired,
    i18n: PropTypes.func.isRequired
};

export default InfoLayoutBelowEvent;
