import PropTypes from 'prop-types';
import React from 'react';

function MediaObject(props) {
  if (props.embedURL && props.shouldLoadMedia) {
    return (
        <div className="mtl">
          <iframe width="336" height="227" src={props.embedURL} frameBorder="0"
                  style={{ display: 'block', margin: '0 auto' }} allowFullScreen title={props.title + ' Media'}>
          </iframe>
        </div>
    );
  } else {
    if (props.image && props.shouldLoadMedia) {
      return (
          <div className="mtl">
            <img itemProp="image" src={props.image} className="info-img" alt=""/>
          </div>
      );
    } else {
      return <div></div>;
    }
  }
}

MediaObject.propTypes = {
  image: PropTypes.string,
  embedURL: PropTypes.string,
  shouldLoadMedia : PropTypes.bool,
  title: PropTypes.string,
};

export default MediaObject;
