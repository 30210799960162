/* global selectableTicketAmounts: false, global existingReservation, global currentEvent
  global eventsWithTicketGroups: false */
import PropTypes from 'prop-types';

import React from 'react';
import Event from './Event.jsx';
import Timer from './Timer.jsx';
import PricePanel from './PricePanel.jsx';
import ErrorAlert from './ErrorAlert.jsx';
import NoTicketsSelected from './NoTicketsSelected.jsx';
import NoEventsOnSelectedDate from "./NoEventsOnSelectedDate.jsx";
import ContinueShoppingButton from './ContinueShoppingButton.jsx';
import CheckoutButton from './CheckoutButton.jsx';
import {
  generateShades,
  getEventName,
  getEventNameWithDatePrefix,
  getOrderPreviewTopOffset,
  refocusWindow,
  updateBadgeNumber
} from './utils.js';
import Cookies from 'js-cookie';
import $ from 'jquery';
import classNames from 'classnames';
import TicketCounter from './TicketCounter.jsx';
import {
  pushCartEventToDataLayer,
  pushViewCartEventToDataLayer,
  pushViewItemEventToDataLayer
} from "./ga4";

class TicketSelector extends React.Component {

  eventIds() {
    const initialEventsToShow = [];
    for (const key in eventsWithTicketGroups) {
      if (eventsWithTicketGroups.hasOwnProperty(key)) {
        initialEventsToShow.push(eventsWithTicketGroups[key].key.id);
      }
    }
    return initialEventsToShow;
  }

  constructor(props) {
    super(props);
    this.checkout = this.checkout.bind(this);
    this.ticketsNumber = this.ticketsNumber.bind(this);
    this.nonEmpty = this.nonEmpty.bind(this);
    this.changeTicketAmount = this.changeTicketAmount.bind(this);
    this.isOrderPreviewOpen = this.isOrderPreviewOpen.bind(this);
    this.closeOrderPreview = this.closeOrderPreview.bind(this);
    this.toggleEventInfo = this.toggleEventInfo.bind(this);
    this.toggleEvent = this.toggleEvent.bind(this);
    this.closeAllGroups = this.closeAllGroups.bind(this);
    this.restoreEvents = this.restoreEvents.bind(this);
    this.handleOrderCountChange = this.handleOrderCountChange.bind(this);
    this.setInitialChosenTickets = this.setInitialChosenTickets.bind(this);
    this.goBack = this.goBack.bind(this);
    this.goBackToLandingPage = this.goBackToLandingPage.bind(this);
    this.eventIds = this.eventIds.bind(this);
    this.reserveTicket = this.reserveTicket.bind(this);
    this.unreserveTicket = this.unreserveTicket.bind(this);
    this.displaySpinnerFor = this.displaySpinnerFor.bind(this);
    this.hideSpinnerFor = this.hideSpinnerFor.bind(this);
    this.displayErrorOrRedirect = this.displayErrorOrRedirect.bind(this);
    this.getTimeLeft = this.getTimeLeft.bind(this);
    this.renewTimer = this.renewTimer.bind(this);
    this.submitTicketSelection = this.submitTicketSelection.bind(this);
    this.pushCartEventToDataLayer = pushCartEventToDataLayer.bind(this);
    this.pushViewCartEventToDataLayer = pushViewCartEventToDataLayer.bind(this);
    this.pushViewItemEventToDataLayer = pushViewItemEventToDataLayer.bind(this);
    this.form = document.getElementById('orderTickets');

    $("#loading-overlay").hide(); //hide overlay and spinner when TicketSelector is instantiated
    this.state = {
      eventInfoShown: this.getEventInfoShown(props),
      submitted: false,
      chosenTickets: {},
      spinnerTicketGroupIds : [], //don't show spinner for any ticketGroupId
      pricing: {
        service: 0,
        orderfee: 0,
        total: 0,
      },
      eventsToShow: this.eventIds(),
      reservationErrors : [],
      openEventId: -1,
      showOrderPreview: false,
      orderPreviewTopOffset: 0,
      largeScreen: window.matchMedia && window.matchMedia('(min-width: 48em)').matches,
      timerEndsAt : existingReservation? existingReservation.secondsLeft * 1000 + new Date().getTime() : null,
      showNotEnoughTicketsAvailableEmptyBasket : false,
      unlockCodeLimit: {}
    };
  }

  onFocus(ticketShopId){
    // Check if the current ticket shop is the same as the ticket shop in the session
    // If not, reload the page to get the right layout and the order from the other shop
    const session = Cookies.get("STAGER_SESSION").split("ticketShopId=")[1]
    let index = 0;
    session.split("").some(char => {
      if (char === "&") {
        return true;
      }
      index++;
      return false;
    })
    if (ticketShopId.toString() !== Cookies.get("STAGER_SESSION").split("ticketShopId=")[1].slice(0,index)) {
      window.location.reload();
    }
  }

  getEventInfoShown(props) {
    return (props.existingReservation == null || props.existingReservation.tickets.length === 0)
              && props.isSingleEventPage && props.unfoldInfo ? props.currentEvent : -1;
  }

  componentDidMount() {
    this.setInitialChosenTickets();
    this.startResolutionWatcher();
    this.startOrderExpirationWatcher();
    window.addEventListener("focus", () => {this.onFocus(this.props.ticketShopId)})
  }

  renewTimer(){
    this.setState({timerEndsAt : new Date().getTime() + 15*60*1000}); //15 min
  }

  getTimeLeft(){
    if(this.state.timerEndsAt == null){
      return 0;
    } else {
      return this.state.timerEndsAt - new Date().getTime();
    }
  }

  getOpenEventId() {
    if (this.props.isSingleEventPage) {
      return this.props.currentEvent;
    } else if (Cookies.get('lockedFlag') !== null && this.state.openEventId === -1) {
      const eventWithPromoUnlockedTickets = parseInt(Cookies.get('lockedFlag'), 10);
      Cookies.set('lockedFlag', null);
      this.setState({ openEventId: eventWithPromoUnlockedTickets });
      return eventWithPromoUnlockedTickets;
    } else {
      return this.state.openEventId;
    }
  }

  setInitialChosenTickets() {
    if (this.props.existingReservation !== null && this.props.existingReservation.tickets !== undefined) {
      const ticketGroupIdsWithCounts = {};
      let shoppingCartBadgeNumber = 0;

      // Specify (indexed with ticketGroup.ids how many tickets are in the this.props.existingReservation
      this.props.existingReservation.tickets.forEach(function countTickets(ticket) {
        const ticketTypeCount = ticketGroupIdsWithCounts[ticket.ticketGroupId];
        const increment = 1 / ticket.ticketTypeWeight;
        ticketGroupIdsWithCounts[ticket.ticketGroupId] = !!ticketTypeCount ? ticketTypeCount + increment : increment ;
        shoppingCartBadgeNumber += increment;
      });

      updateBadgeNumber(Math.round(shoppingCartBadgeNumber));

      const setInitialTicketCount = this.handleOrderCountChange;

      // Load them in the chosenTicket state using this.handleOrderCountChange
      eventsWithTicketGroups.forEach(function setTicketCounts(eventWithTicketGroups) {
        const event = eventWithTicketGroups;

        eventWithTicketGroups.value.forEach(function setGroupTicketCount(ticketGroup) {
          if (ticketGroupIdsWithCounts[ticketGroup.id] !== undefined) {
            setInitialTicketCount(event.key.id, ticketGroup, Math.round(ticketGroupIdsWithCounts[ticketGroup.id]), true);
          }
        });
      });
    }
  }

  startResolutionWatcher() {
    window.addEventListener('resize', function adaptToResolution(e) {
      e.target.TicketSelector.setState({
        largeScreen: window.matchMedia && window.matchMedia('(min-width: 48em)').matches,
      });
    });
  }

  submitTicketSelection() {
    this.form.submit();
  }

  checkout() {
    if (this.ticketsNumber() > 0) {
      this.setState({ submitted: true });
      this.submitTicketSelection();
    } else {
      this.setState({ submitError: true });
    }
  }

  goBack() {
    if (this.props.isSingleEventPage) {
      this.form.elements.continueShopping.value = true;
      this.submitTicketSelection();
    } else if (this.props.landingPageIsCalendar) {
      this.goBackToLandingPage();
    } else {
      this.restoreEvents();
      document.getElementById('filter').click();
      this.closeOrderPreview();
      this.closeAllGroups();
    }
  }

  goBackToLandingPage() {
    $.ajax({
      url: '/web/tickets/landingpage',
      type: 'GET',
      data: {
        id: this.props.ticketShopId
      }
    }).done(function (url) {
      window.document.location = url;
    });
  }

  fetchUnlockCodeLimit(ticketGroupId, unlockCode) {
    return $.ajax({
      url: `/web/tickets/locked-code-limit`,
      type: 'GET',
      data: {
        ticketGroupId: ticketGroupId,
        unlockCode: unlockCode
      }
    }).then(data => {
      return data.limit;
    }).catch(error => {
      console.error("Error:", error);
      throw error; // or handle error as needed
    });
  }

   checkMaySelectMoreTickets(eventId, ticketGroup) {
    const chosenTicketsAmountForEventAndTicketGroup =
      this.state.chosenTickets[eventId] && this.state.chosenTickets[eventId].tickets[ticketGroup.id] ?
      this.state.chosenTickets[eventId].tickets[ticketGroup.id].count : 0;

    const unlockCodeRequired = this.state.chosenTickets[eventId].tickets[ticketGroup.id].ticketGroup.ticketType.unlockCodeRequired;

     if (unlockCodeRequired) {
       const cookieValue = document.cookie.split("; ").find(
         (s) => s.startsWith("STAGER_SESSION") === true)

       if (cookieValue !== undefined) {
         const unlockCode = cookieValue.split("insertedUnlockCodes=")[1]
          .split("&")[0]
         if (!this.state.unlockCodeLimit[unlockCode]) {
           this.fetchUnlockCodeLimit(ticketGroup.id, unlockCode).then(
             (limit) => {
               this.setState(prevState => ({
                 unlockCodeLimit: {
                   ...prevState.unlockCodeLimit,
                   [unlockCode]: limit
                 }
               }));
             })
         }
         return chosenTicketsAmountForEventAndTicketGroup != null
           && chosenTicketsAmountForEventAndTicketGroup
           < this.state.unlockCodeLimit[unlockCode]
       }
    } else {
      const ticketMax = selectableTicketAmounts[ticketGroup.id] ? selectableTicketAmounts[ticketGroup.id] : 0;
      return chosenTicketsAmountForEventAndTicketGroup != null && chosenTicketsAmountForEventAndTicketGroup < ticketMax;
    }
  }

  changeTicketAmount(eventId, ticketGroup, decrease) {
    let numberToUpdateTo;
    const chosenTicketsAmountForEventAndTicketGroup =
      this.state.chosenTickets[eventId] && this.state.chosenTickets[eventId].tickets[ticketGroup.id] ?
        this.state.chosenTickets[eventId].tickets[ticketGroup.id].count : 0;
    const ticketMax = selectableTicketAmounts[ticketGroup.id] ? selectableTicketAmounts[ticketGroup.id] : 0;

    if (decrease) {
      numberToUpdateTo = chosenTicketsAmountForEventAndTicketGroup ? chosenTicketsAmountForEventAndTicketGroup - 1 : 0;
    } else {
      numberToUpdateTo = chosenTicketsAmountForEventAndTicketGroup ? chosenTicketsAmountForEventAndTicketGroup + 1 : 1;
    }
    if (numberToUpdateTo <= ticketMax) {
      this.handleOrderCountChange(eventId, ticketGroup, numberToUpdateTo);
    }
    this.openOrderPreview();
    this.setState({ submitError: false });
  }

  toggleEvent(eventId) {
    if (this.state.openEventId === eventId) {// Close the Event if it was already opened
      this.setState({ openEventId: -1 });
    } else {// Open otherwise
      this.pushViewItemEventToDataLayer(eventId)
      this.setState({ openEventId: eventId });
    }
    // Close info panel
    this.setState({ eventInfoShown: -1 });
  }

  toggleEventInfo(id) {
    if (this.state.eventInfoShown === id) {
      this.setState({ eventInfoShown: -1 });
    } else {
      this.closeOrderPreview();
      this.setState({ eventInfoShown: id });
    }
  }

  displaySpinnerFor(ticketGroupId) {
    let ticketGroupIds = this.state.spinnerTicketGroupIds;
    ticketGroupIds.push(ticketGroupId.toString());
    this.setState({ spinnerTicketGroupIds : ticketGroupIds });
  }

  hideSpinnerFor(ticketGroupId){
    var ticketGroupIds = this.state.spinnerTicketGroupIds;
    var ticketGroupIdIndex = ticketGroupIds.indexOf(ticketGroupId.toString());
    if(ticketGroupIdIndex !== -1){
      delete ticketGroupIds[ticketGroupIdIndex];
      this.setState({spinnerTicketGroupIds : ticketGroupIds});
    }
  }

  displayErrorOrRedirect(error){
    //todo this only shows the last validation error, clearing any previous error. Is this ok?

    if(error === "paymentStarted"){
      window.document.location = "/web/tickets/inprogress"; //redirect to the paymentInProgress page
      return;
    }

    if(error){
      if(this.ticketsNumber() < 1){
        this.setState({showNotEnoughTicketsAvailableEmptyBasket: true, reservationErrors : [error]})
      } else {
        this.setState({reservationErrors: [error]});
      }
    } else {
      this.setState({reservationErrors: []});
    }

  }

  startOrderExpirationWatcher(){
    const getTimeLeft = this.getTimeLeft;

    var interval = window.setInterval(function checkShouldRefreshPage(){
      if(getTimeLeft() < 0){
        window.clearInterval(interval);
        document.location.reload(true);
      }
    },1000)
  }


  reserveTicket(ticketGroup, eventId){
    if (this.state.spinnerTicketGroupIds.indexOf(ticketGroup.id.toString()) !== -1) {
      return;
    }

    const updateTicketList = this.changeTicketAmount;
    const displaySpinner = this.displaySpinnerFor;
    const hideSpinner = this.hideSpinnerFor;
    const displayErrorMessageOrRedirect = this.displayErrorOrRedirect;
    const renewTimer = this.renewTimer;
    const pushCartEventToDataLayer = this.pushCartEventToDataLayer;

    $.ajax({
      url: '/web/tickets/reserve?authenticityToken=' + $('#authenticityToken').val(),
      type: 'POST',
      data: {
        "ticketGroupId": ticketGroup.id
      },
      beforeSend : function(){
        displaySpinner(ticketGroup.id); //display spinner
        displayErrorMessageOrRedirect(); //clear any previous reservation error
      },
      dataType: 'json'
    }).fail(response => {
      this.setState({ orderPreviewTopOffset: getOrderPreviewTopOffset(eventId) });
      var error;
      try{
        error = JSON.parse(response.responseText);
        for(var key in error){
          if(error.hasOwnProperty(key)){
            error[key].forEach(displayErrorMessageOrRedirect);
          }
        }
      } catch (exception){
        displayErrorMessageOrRedirect("Unexpected error occurred, please refresh the page."); //todo i18n
      }
    }).done(function(){
      updateTicketList(eventId,ticketGroup);
      renewTimer();
      pushCartEventToDataLayer('add_to_cart', ticketGroup, eventId)
    }).always(function(){
      hideSpinner(ticketGroup.id);
    });
  }

  unreserveTicket(ticketGroup, eventId){
    const updateTicketList = this.changeTicketAmount;
    const displaySpinner = this.displaySpinnerFor;
    const hideSpinner = this.hideSpinnerFor;
    const displayErrorMessageOrRedirect = this.displayErrorOrRedirect;
    const renewTimer = this.renewTimer;
    const pushCartEventToDataLayer = this.pushCartEventToDataLayer;

    $.ajax({
      url: '/web/tickets/unreserve?authenticityToken=' + $('#authenticityToken').val(),
      type: 'POST',
      data: {
        ticketGroupId: ticketGroup.id
      },
      beforeSend: function () {
        displaySpinner(ticketGroup.id);
        displayErrorMessageOrRedirect(); //clear any previous reservation error
      },
      dataType: 'json'
    }).fail(function(response){

      var error;

      try{
        error = JSON.parse(response.responseText);
        for(var key in error){
          if(error.hasOwnProperty(key)){
            error[key].forEach(displayErrorMessageOrRedirect);
          }
        }
      } catch (exception){
        displayErrorMessageOrRedirect("Unexpected error occurred, please refresh the page."); //todo i18n
      }

    }).done(function(){
      updateTicketList(eventId,ticketGroup,true);
      renewTimer();
      pushCartEventToDataLayer('remove_from_cart', ticketGroup, eventId)
    }).always(function(){
      hideSpinner(ticketGroup.id);
    });

  }

  handleOrderCountChange(eventId, ticketGroup, count, isLoadingExistingOrder) {
    if (this.state.chosenTickets[eventId] === undefined) {
      this.setState(prevState => {
        const newChosenTickets = prevState.chosenTickets;
        const currentTickets = newChosenTickets[eventId] ? newChosenTickets[eventId].tickets : {}
        currentTickets[ticketGroup.id] = {
          count: count,
          ticketGroup: ticketGroup
        };
        newChosenTickets[eventId] = {
          event: eventId,
          tickets: currentTickets,
        }
        this.calculatePricing(newChosenTickets);
        return { chosenTickets: newChosenTickets }
      })
    }

    if (count === 0) {
      delete this.state.chosenTickets[eventId].tickets[ticketGroup.id];
      this.calculatePricing(this.state.chosenTickets);
    } else {
      this.setState(prevState => {
        const newChosenTickets = prevState.chosenTickets;
        newChosenTickets[eventId].tickets[ticketGroup.id] = {
          ticketGroup: ticketGroup,
          count: count,
        }
        this.calculatePricing(newChosenTickets);
        return { chosenTickets: newChosenTickets }
      })
    }

    const ticketsNumber = this.ticketsNumber();
    updateBadgeNumber(ticketsNumber);
    if (ticketsNumber === 0) {
      this.closeOrderPreview();
    }
    if (!isLoadingExistingOrder) {
      const basketPositionY = getOrderPreviewTopOffset(eventId);
      this.setState({ orderPreviewTopOffset: getOrderPreviewTopOffset(eventId) });
      refocusWindow(basketPositionY);
    }
  }

  eventTicketsNumber(eventId) {
    let number = 0;
    const chosenTickets = this.state.chosenTickets[eventId];

    if (chosenTickets !== undefined) {
      for (const ticketGroupId in chosenTickets.tickets) {
        if (chosenTickets.tickets.hasOwnProperty(ticketGroupId)) {
          number += chosenTickets.tickets[ticketGroupId].count;
        }
      }
    }
    return number;
  }

  ticketsNumber() {
    let number = 0;

    for (const eventId in this.state.chosenTickets) {
      if (this.state.chosenTickets.hasOwnProperty(eventId)) {
        number += this.eventTicketsNumber(eventId);
      }
    }

    return number;
  }

  nonEmpty() {
    return this.ticketsNumber() > 0;
  }

  calculatePricing(chosenTickets) {
    let subtotal = 0;
    let serviceCosts = 0;

    for (const eventId in chosenTickets) {
      if (chosenTickets.hasOwnProperty(eventId)) {
        const eventTickets = chosenTickets[eventId].tickets;

        for (const ticketGroupId in eventTickets) {
          if (eventTickets.hasOwnProperty(ticketGroupId)) {
            const ticketGroupCount = eventTickets[ticketGroupId];

            const count = ticketGroupCount.count;
            const price = ticketGroupCount.ticketGroup.price;
            const ticketTypeServiceCosts = ticketGroupCount.ticketGroup.webFee;
            const weight = ticketGroupCount.ticketGroup.ticketType.weight;

            subtotal += count * price;
            serviceCosts += count * ticketTypeServiceCosts * weight;
          }
        }
      }
    }

    const total = subtotal + serviceCosts + this.props.currentOrganizationFee;

    this.setState({ pricing: {
      service: serviceCosts,
      orderfee: this.props.currentOrganizationFee,
      total: total,
    } }
    );
  }

  restoreEvents() {
    this.setState({ eventsToShow: this.eventIds() });
  }

  filterEvents(eventIds) {
    this.setState({ eventsToShow: eventIds, eventInfoShown: -1 });
  }

  closeAllGroups() {
    this.setState({ openEventId: -1 });
  }

  openOrderPreview() {
    this.pushViewCartEventToDataLayer();
    this.setState({ showOrderPreview: true });
    if (!this.state.largeScreen) {
      window.scrollTo(0, 0);
    }
  }

  closeOrderPreview() {
    this.setState({ showOrderPreview: false });
  }

  isOrderPreviewOpen() {
    return this.state.showOrderPreview;
  }

  isCurrentEvent(eventAndTicketGroups) {
    return eventAndTicketGroups.key.id === currentEvent;
  }

  render() {
    updateBadgeNumber(this.ticketsNumber());
    const eventListClass = 'animated slideInLeft fl mw-col' + (this.state.eventsToShow.length === 0 ? ' hidden' : '');
    const shades = generateShades(this.props.colorDark, this.props.colorLight,
      eventsWithTicketGroups.length, this.props.numberOfShades);
    const openEventId = this.getOpenEventId();

    const eventsTicketsAmountsAndPrices = [];

    for (const eventId in this.state.chosenTickets) {
      if (this.state.chosenTickets.hasOwnProperty(eventId)) {
        const ticketsAmountsAndPrices = [];
        const tickets = this.state.chosenTickets[eventId].tickets;
        let title = getEventName(this.state.chosenTickets[eventId].event);

        if (this.props.landingPageIsCalendar) {
          title = getEventNameWithDatePrefix(title, eventId)
        }

        for (const ticketGroupId in tickets) {
          if (tickets.hasOwnProperty(ticketGroupId)) {
            const ticketCount = tickets[ticketGroupId];
            const that = this;
            ticketsAmountsAndPrices.push(
              <TicketCounter
                decrement={function decrementCount() {
                  that.unreserveTicket(ticketCount.ticketGroup, eventId);
                }}
                increment={function incrementCount() {
                  that.reserveTicket(ticketCount.ticketGroup, eventId);
                }}
                colorSecondary={this.props.colorSecondary}
                count={ticketCount.count}
                name={ticketCount.ticketGroup.ticketType.name}
                key={ticketGroupId}
                title={title}
                id={ticketGroupId}
                price={ticketCount.ticketGroup.price}
                maySelectMoreTickets={that.checkMaySelectMoreTickets(eventId, ticketCount.ticketGroup)}
                spinnerTicketGroupIds={that.state.spinnerTicketGroupIds}
              />
            );
          }
        }

        eventsTicketsAmountsAndPrices.push(ticketsAmountsAndPrices);
      }
    }
    const timeLeft = this.getTimeLeft();
    const ticketPanel = (
      <div className={classNames('ticket-list mw-col fl bg-white animated', { slideInLeft:
            this.props.existingReservation, fadeIn: !this.props.existingReservation, hidden: !this.state.showOrderPreview })}
           id="orderPreview"
           style={ this.state.largeScreen ? { marginTop: this.state.orderPreviewTopOffset + 'px' } : {} }>
        <div>
          {eventsTicketsAmountsAndPrices}
        </div>
        <PricePanel totalPrice={this.state.pricing.total}
                    servicePrice={this.state.pricing.service}
                    orderFee={this.state.pricing.orderfee}
                    totalPriceLegend={this.props.i18n('web.ticketselection.shoppingCartPanel.total')}
                    servicePriceLegend={this.props.i18n('web.ticketselection.shoppingCartPanel.serviceCosts')}
                    orderFeeLegend={this.props.i18n('web.ticketselection.shoppingCartPanel.orderFee')}
        />
        <Timer initialTimeRemaining={timeLeft} key={timeLeft}/>

        {this.state.reservationErrors.map(function renderErrors(error, index) {return (<ErrorAlert key={index} show message={error}/>);})}

        <CheckoutButton onClick={this.checkout}
                        submitted={this.state.submitted}
                        message={this.props.i18n('web.ticketselection.shoppingCartPanel.checkout')}
                        colorSecondary={this.props.colorSecondary}/>

        <ErrorAlert show={this.state.submitError} message={this.props.i18n('web.ticketselection.selectTicket')}/>

        <ContinueShoppingButton onClick={this.goBack}
                                message={this.props.i18n('web.ticketselection.shoppingCartPanel.continueShopping')}
                                colorDark={this.props.colorDark}/>
      </div>);

    const noTicketsMessage = (
      <NoTicketsSelected hidden={ !this.state.showOrderPreview || this.state.largeScreen }
                         largeScreen={ this.state.largeScreen }
                         orderPreviewTopOffset={ this.state.orderPreviewTopOffset }
                         i18n={this.props.i18n}>
          <ContinueShoppingButton onClick={this.goBack}
                                  message={this.props.i18n('web.ticketselection.shoppingCartPanel.continueShopping')}
                                  colorDark={this.props.colorDark}/>
      </NoTicketsSelected>);

    const noEventsOnSelectedDateMessage = (
        <NoEventsOnSelectedDate hidden={ !this.props.noTicketsOnSelectedDate }
                           largeScreen={ this.state.largeScreen }
                           orderPreviewTopOffset={ this.state.orderPreviewTopOffset }
                           i18n={this.props.i18n}>
          <ContinueShoppingButton onClick={this.goBack}
                                  message={this.props.i18n('web.ticketselection.backToCalendar')}
                                  colorDark={this.props.colorDark}/>
        </NoEventsOnSelectedDate>);

    const notEnoughTicketsAvailableEmptyBasket = (
        <div className={classNames('ticket-list fl mw-col bg-white fadeIn animated')}
             style={{ marginTop: this.state.orderPreviewTopOffset + 'px' }}>
          {this.state.reservationErrors.map(function renderErrors(error,index) {return (
            <div className="bg-red pvm phl error-box-flex" role="alert">
              <div class="circle bg-white f2 fw7 color-red symmetric mvm">!</div>
              <div class="color-white error-message-align" key={index}>{error}</div>
            </div>
          );})}
          <ContinueShoppingButton onClick={this.goBack}
                                  message={this.props.i18n('web.ticketselection.shoppingCartPanel.continueShopping')}
                                  colorDark={this.props.colorDark}/>
        </div>
    );

    let eventsForDisplay = this.props.currentEvent !== undefined ? eventsWithTicketGroups.filter(this.isCurrentEvent) : eventsWithTicketGroups;

    return (
      <div>
            <div className="mw-col animated slideInLeft">
              {this.props.errors.map(function renderErrors(error, index) {return (<ErrorAlert key={index} show message={error}/>);})}
            </div>
        {noEventsOnSelectedDateMessage}
        <div className={eventListClass}>
                {eventsForDisplay.map(function renderEvent(eventAndTicketGroups, index) {
                  const id = eventAndTicketGroups.key.id;
                  const show = this.state.eventsToShow.indexOf(id) > -1 && (this.state.largeScreen || !this.isOrderPreviewOpen());
                  const backgroundColor = shades[index];

                  const previousIndex = index - 1 < 0 ? 0 : index - 1;
                  const topBorderColor = shades[previousIndex];

                  const nextIndex = index + 1 > eventsWithTicketGroups.length ? index : index + 1;
                  const bottomBorderColor = shades[nextIndex];

                  const collapsed = openEventId !== id;
                  return (
                    <Event
                      key={id}

                      index={index}
                      eventId={id}
                      allEventIds={this.eventIds()}
                      displayEventNamesFirst={this.props.displayEventNamesFirst}
                      currentOrganizationFee={this.props.currentOrganizationFee}

                      programStartTimeString={eventAndTicketGroups.key.programStartTimeString}
                      programStartDateString={eventAndTicketGroups.key.programStartDateString}
                      programStartDayFormatted={eventAndTicketGroups.key.programStartDayFormatted}
                      programStartDayNameShort={eventAndTicketGroups.key.programStartDayNameShort}

                      programEndTimeString={eventAndTicketGroups.key.programEndTimeString}
                      programEndDateString={eventAndTicketGroups.key.programEndDateString}
                      programEndDayFormatted ={eventAndTicketGroups.key.programEndDayFormatted}
                      programEndDayNameShort ={eventAndTicketGroups.key.programEndDayNameShort}
                      isEventEndTimeShown = {eventAndTicketGroups.key.isEventEndTimeShown}

                      firstSaleDate={eventAndTicketGroups.key.firstSaleDateTimeStamp}
                      isBeforeSaleStart={eventAndTicketGroups.key.isBeforeSaleStart}
                      saleStartMode={eventAndTicketGroups.key.saleStartMode}
                      isLastEvent={eventsForDisplay.length === index + 1}

                      spinnerTicketGroupIds={this.state.spinnerTicketGroupIds}
                      ticketGroups={eventAndTicketGroups.value}
                      ticketsNumber={this.eventTicketsNumber(id)}
                      reserveTicket={this.reserveTicket}

                      backgroundColor={backgroundColor}
                      topBorderColor={topBorderColor}
                      bottomBorderColor={bottomBorderColor}
                      colorSecondary={this.props.colorSecondary}

                      collapsed={collapsed}
                      show={show}
                      showLocked={eventAndTicketGroups.key.hasUnlockableTicketGroups}
                      toggleEvent={this.toggleEvent}
                      toggleEventInfo={this.toggleEventInfo}
                      landingPageIsCalendar={this.props.landingPageIsCalendar}
                      eventInfoShown={this.state.eventInfoShown === id}
                      displayInfoButton={eventAndTicketGroups.key.eventInfo.showOnTicketShop}

                      title={eventAndTicketGroups.key.name}
                      bodyTitle={eventAndTicketGroups.key.eventInfoTitle}
                      subtitle={eventAndTicketGroups.key.eventInfo.eventInfoSubtitle}
                      description={eventAndTicketGroups.key.eventInfo.eventInfoDescription}
                      locationDetails={eventAndTicketGroups.key.locationForTicket.details}
                      coverMedia={eventAndTicketGroups.key.eventInfo.eventInfoCoverMedia}
                      largeScreen={this.state.largeScreen}
                      i18n={this.props.i18n}
                    />
                  );
                }, this)}
            </div>
        {this.ticketsNumber() > 0 ? ticketPanel : (this.state.showNotEnoughTicketsAvailableEmptyBasket && this.state.reservationErrors.length > 0 ? notEnoughTicketsAvailableEmptyBasket : noTicketsMessage)}
      </div>
    );
  }
}

TicketSelector.propTypes = {
  currentOrganizationFee: PropTypes.number,
  existingReservation: PropTypes.object,
  colorLight: PropTypes.string.isRequired,
  colorDark: PropTypes.string.isRequired,
  colorSecondary: PropTypes.string.isRequired,
  isSingleEventPage: PropTypes.bool,
  currentEvent: PropTypes.number,
  unfoldInfo: PropTypes.bool,
  errors: PropTypes.array.isRequired,
  numberOfShades: PropTypes.number,
  displayEventNamesFirst : PropTypes.bool,
  landingPageIsCalendar: PropTypes.bool,
  eventsWithTicketGroups: PropTypes.array.isRequired,
  noEventsOnSelectedDate: PropTypes.bool,
  noTicketsOnSelectedDate: PropTypes.bool,
  i18n: PropTypes.func.isRequired,
  ticketShopId: PropTypes.number,
};

export default TicketSelector;
