/* global eventsWithTicketGroups: false */
import $ from 'jquery';
// Takes amount of cents XYZ and returns formatted price "€X,YZ"
function formatPrice(price) {
  function roundTwoDecimals(number) {
    return Math.round(number * 100) / 100;
  }

  return '€' + roundTwoDecimals(price / 100).toFixed(2);
}

function getContrastingColor(color) {
  var currentColor = color.replace('#', '');
  var red = parseInt(currentColor.substr(0, 2), 16);
  var green = parseInt(currentColor.substr(2, 2), 16);
  var blue = parseInt(currentColor.substr(4, 2), 16);

  var brightness = ( 299 * red + 587 * green + 144 * blue ) / 2550
  return brightness > 60 ? 'color-black' : 'color-white';
}

function getContrastingMaskColor(color) {
  var currentColor = color.replace('#', '');
  var red = parseInt(currentColor.substr(0, 2), 16);
  var green = parseInt(currentColor.substr(2, 2), 16);
  var blue = parseInt(currentColor.substr(4, 2), 16);

  var brightness = ( 299 * red + 587 * green + 144 * blue ) / 2550
  return brightness > 60 ? 'bg-black' : 'bg-white';
}

function generateShades(lightHexColor, darkHexColor, steps, numberOfShades) {
  const lightColor = parseHexColor(lightHexColor);
  const darkColor = parseHexColor(darkHexColor);
  let shades = [];

  const toHexColor = function toHexColor(red, green, blue) {
    const toHex = function toHex(number) {
      const hex = number.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };
    return ('#' + toHex(red) + '' + toHex(green) + '' + toHex(blue));
  };

  if (steps === 1) {
    const red = Math.round((lightColor.red + darkColor.red) / 2);
    const green = Math.round((lightColor.green + darkColor.green) / 2);
    const blue = Math.round((lightColor.blue + darkColor.blue) / 2);
    shades.push(toHexColor(red, green, blue));
  } else if (steps > 1) {
    const redIncrement = (darkColor.red - lightColor.red) / (numberOfShades - 1);
    const greenIncrement = (darkColor.green - lightColor.green) / (numberOfShades - 1);
    const blueIncrement = (darkColor.blue - lightColor.blue) / (numberOfShades - 1);
    for (let colorDivision = 0; colorDivision < numberOfShades; colorDivision++) {
      const red = Math.round(lightColor.red + redIncrement * colorDivision);
      const green = Math.round(lightColor.green + greenIncrement * colorDivision);
      const blue = Math.round(lightColor.blue + blueIncrement * colorDivision);
      shades.push(toHexColor(red, green, blue));
    }
  }
  const shadesToConcat = shades.slice();

  for (let index = 0; Math.ceil((steps - numberOfShades) / (numberOfShades - 1)) > index; index++) {
    shadesToConcat.reverse();
    shades = shades.concat(shadesToConcat.slice(1));
  }
  return shades;
}

function getEventName(eventId) {
  return eventsWithTicketGroups.filter(function filterById(event) {
    return event.key.id === eventId;
  })[0].key.name;
}

function getEventNameWithDatePrefix(title, eventId) {
  const matchingGroup = eventsWithTicketGroups.filter(group => group.key.id === Number(eventId))
  return matchingGroup[0].key.programStartDayFormatted + " " + title;
}

function updateBadgeNumber(numberToUpdateTo) {
  if (numberToUpdateTo === 0) {
    $('.shopping-badge').hide();
  } else {
    $('.shopping-badge').text(numberToUpdateTo).css('display', 'flex').stop(true, false)
        .animate({ height: '3.4rem', width: '3.4rem', fontSize: '2.8rem' }, 200)
        .animate({ height: '1.8rem', width: '1.8rem', fontSize: '1.4rem' }, 200);
  }
}

/* subtract header height && beta notification height */
function getOrderPreviewTopOffset(eventId) {
  if ($('[data-event-id=' + eventId + ']').length === 0) {
    return 0;
  }
  return $('[data-event-id=' + eventId + ']').parent().offset().top - 45;
}

function getShareConfirmationTopOffset() {
  if ($('.shareable-container').length === 0) {
    return 0;
  }
  return $('.shareable-container').offset().top - 45;
}

function getWithdrawConfirmationTopOffset(sharelinkId) {
  if ($('[data-share-link-id=' + sharelinkId + ']').length === 0) {
    return 0;
  }
  return $('[data-share-link-id=' + sharelinkId + ']').offset().top - 45;
}

function refocusWindow(offset) {
  $('html, body').animate({ scrollTop: offset + 'px' });
}

function parseHexColor(color) {
  return {
    'red': parseInt(color.substr(0, 2), 16),
    'green': parseInt(color.substr(2, 2), 16),
    'blue': parseInt(color.substr(4, 2), 16),
    'hexValue': color,
  };
}

export {
  formatPrice,
  getContrastingColor,
  getContrastingMaskColor,
  generateShades,
  getEventName,
  getEventNameWithDatePrefix,
  updateBadgeNumber,
  getOrderPreviewTopOffset,
  getShareConfirmationTopOffset,
  getWithdrawConfirmationTopOffset,
  refocusWindow
};
