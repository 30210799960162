import PropTypes from 'prop-types';
import React from 'react';

function Border(props) {
  if (props.show) {
    return (
        <svg width="100%" height="6px" style={{ float: 'left', top: props.top,
          position: 'relative', left: '-20px', width: '111%' }}>
            <line x="0" x2="100%" y1="50%" y2="50%" stroke={props.color} strokeWidth="6" strokeLinecap="round" strokeDasharray={'1,' + props.gap}/>
        </svg>
    );
  } else {
    return <div style={{ display: 'none' }}></div>;
  }
}

Border.propTypes = {
  color: PropTypes.string,
  show: PropTypes.bool,
  top: PropTypes.string,
  gap: PropTypes.number,
};

Border.defaultProps = {
  top: '0.7rem',
  gap: 12,
};

export default Border;
