/* global errors: false, currentOrganizationFee: false, existingReservation: false, existingReservation: false, unfoldInfo: false,
   colorLight: false, colorDark: false, colorSecondary: false, displayEventNamesFirst: false, noTicketsOnSelectedDate: false, isSingleEventPage: false, landingPageIsCalendar: false,
   openBasketOnPageLoad: false, eventsWithTicketGroups: false, i18n: false, currentEvent: false, ticketShopId: false */
import 'core-js/es6/map';
import 'core-js/es6/set';
import React from 'react';
import ReactDOM from 'react-dom';
import TicketSelector from './lib/TicketSelector.jsx';

const TicketSelectorInstance = ReactDOM.render(
  <TicketSelector
    currentOrganizationFee={currentOrganizationFee}
    existingReservation={existingReservation}
    colorLight={colorLight}
    colorDark={colorDark}
    colorSecondary={colorSecondary}
    isSingleEventPage={isSingleEventPage}
    noTicketsOnSelectedDate={noTicketsOnSelectedDate}
    currentEvent={currentEvent}
    unfoldInfo={unfoldInfo}
    errors={errors}
    numberOfShades={10}
    displayEventNamesFirst={displayEventNamesFirst}
    landingPageIsCalendar={landingPageIsCalendar}
    eventsWithTicketGroups={eventsWithTicketGroups}
    i18n={i18n}
    ticketShopId={ticketShopId}
  />,
      document.getElementById('ticket-selector')
);


// Make sure to open order preview if there's an existingReservation and not if the user comes from the singleEventTicketShop
if ((existingReservation.tickets.length !== 0 && openBasketOnPageLoad) && TicketSelectorInstance.state.largeScreen) {
  TicketSelectorInstance.openOrderPreview();
}

document.getElementById('shopping-cart-clicker').addEventListener('click', function toggleOrderPreview() {
  if (TicketSelectorInstance.isOrderPreviewOpen()) {
    TicketSelectorInstance.closeOrderPreview();
  } else {
        // on mobile eventInfo is inline, no need to hide it as it doesn't interfere with the shopping cart column
    if (TicketSelectorInstance.state.eventInfoShown && TicketSelectorInstance.state.largeScreen) {
      TicketSelectorInstance.toggleEventInfo();
    }
    TicketSelectorInstance.openOrderPreview();
  }
});

window.TicketSelector = TicketSelectorInstance;
