import {getEventName, getEventNameWithDatePrefix} from "./utils";

function pushCartEventToDataLayer(event, ticketGroup, eventId) {
  if (window.dataLayer === undefined) return;

  let eventWithTicketGroups = this.props.eventsWithTicketGroups.find(group => group.key.id === Number(eventId))

  window.dataLayer.push({
    event: event,
    currency: 'EUR',
    value: ticketGroup.price / 100,
    items: [{
      item_id: ticketGroup.id,
      item_name: ticketGroup.ticketType && (eventWithTicketGroups.key.name + " " + ticketGroup.ticketType.name),
      price: (ticketGroup.price + ticketGroup.webFee) / 100,
      quantity:  ticketGroup.ticketType && ticketGroup.ticketType.weight,
      item_list_id: eventWithTicketGroups && eventWithTicketGroups.key.id,
      item_list_name: eventWithTicketGroups && eventWithTicketGroups.key.name
    }]
  });
}

function pushViewCartEventToDataLayer() {
  if (window.dataLayer === undefined) return;

  const ticketGroups = [];

  for (const eventId in this.state.chosenTickets) {
    if (this.state.chosenTickets.hasOwnProperty(eventId)) {
      const tickets = this.state.chosenTickets[eventId].tickets;

      let title = getEventName(
          this.state.chosenTickets[eventId].event);

      if (this.props.landingPageIsCalendar) {
        title = getEventNameWithDatePrefix(title, eventId)
      }

      for (const ticketGroupId in tickets) {
        if (tickets.hasOwnProperty(ticketGroupId)) {
          const ticketCount = tickets[ticketGroupId];
          ticketGroups.push({
            item_id: ticketGroupId,
            item_name: title + " " + ticketCount.ticketGroup.ticketType.name,
            item_list_id: this.state.chosenTickets[eventId].event,
            item_list_name: title,
            price: (ticketCount.ticketGroup.price + ticketCount.ticketGroup.webFee) / 100,
            quantity: ticketCount.ticketGroup.ticketType.weight * ticketCount.count
          });
        }
      }

    }
  }

  window.dataLayer.push({
    event: 'view_cart',
    value: this.state.pricing.total / 100,
    currency: 'EUR',
    items: ticketGroups
  })
}

function pushViewItemEventToDataLayer(eventId) {
  if (window.dataLayer === undefined) return;

  let eventName;

  if (this.props.eventsWithTicketGroups) {
    const ticketGroups = [];
    for (const [key, value] of Object.entries(this.props.eventsWithTicketGroups)) {
      if (key.id !== eventId) continue;

      eventName = key.name;

      value.forEach(ticketGroup => {
        ticketGroups.push({
          item_id: ticketGroup.id,
          item_name: ticketGroup.ticketType && key.name + " "
              + ticketGroup.ticketType.name,
          price: (ticketGroup.price + ticketGroup.webFee) / 100,
          currency: 'EUR',
          quantity: ticketGroup.ticketType && ticketGroup.ticketType.weight
        });
      });
    }

    window.dataLayer.push({
      event: 'view_item_list',
      item_list_id: eventId,
      item_list_name: eventName,
      items: ticketGroups
    });
  }
}

export { pushCartEventToDataLayer, pushViewCartEventToDataLayer, pushViewItemEventToDataLayer };
