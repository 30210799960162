  import PropTypes from 'prop-types';
  import React from 'react';
  import { formatPrice, getContrastingColor } from './utils.js';
  import classNames from 'classnames';

  function TicketCounter(props) {
      const incrementTicketColor = getContrastingColor(props.colorSecondary);
      const decrementTicketColor = getContrastingColor(props.maySelectMoreTickets ? props.colorSecondary : "#CCCCCC");

      return (
          <div className="ticket-border bg-white rect--responsive cf phl pvl mw-col">
            <h2 className="f-18 lh-title truncate mbm fw7">{props.title}</h2>
            <table className="fixed">
              <tbody>
              <tr>
                <td>
                  <div className="f-18 truncate">{props.name}</div>
                </td>
                <td>
                  <div className={classNames('fl ml4',{ hidden : props.spinnerTicketGroupIds.indexOf(props.id) === -1})}>
                    <i className="fa fa-spinner fa-2x fa-spin-pulse fl" aria-hidden="true" />
                  </div>
                  <div className={classNames('fl',{ hidden : props.spinnerTicketGroupIds.indexOf(props.id) !== -1})}>
                    <button data-test="decrement-ticket" onClick={props.decrement} className={classNames('spin fw7 bg--secondary ' + decrementTicketColor)} type="button">-</button>
                    <input type="text" readOnly className="incrementer-count f2 fw7" value={props.count} id={props.id}/>
                    <button data-test="increment-ticket" onClick={props.increment} className={classNames('spin fw7 ' + incrementTicketColor, { 'bg--secondary':
                        props.maySelectMoreTickets, 'bg--lightest-silver': !props.maySelectMoreTickets })}
                            type="button">+
                    </button>
                  </div>
                  <div className="f-18 fr mlm">{formatPrice(props.price * props.count)}</div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
      );
  }

  TicketCounter.propTypes = {
    decrement: PropTypes.func.isRequired,
    increment: PropTypes.func.isRequired,
    count: PropTypes.number,
    name: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.number,
    id: PropTypes.string,
    maySelectMoreTickets: PropTypes.bool.isRequired,
    spinnerTicketGroupIds: PropTypes.array,
    colorSecondary: PropTypes.string,
  };

  export default TicketCounter;
