/* global i18n : false*/
import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';
import { formatPrice } from './utils.js';

function OrderFee(props) {
  return (
    <div className={classNames('timer pvm phl f3', props.displayAsNotification ? 'bg--info-blue' : 'bg--secondary')} >
      <span className={classNames('fl', props.displayAsNotification ? 'color-white' : 'color-dark')}>
        { i18n('ticketSale.preview.tickets.orderFee.notification', formatPrice(props.orderFee)) }
      </span>
    </div>
  );
}

OrderFee.propTypes = {
  orderFee: PropTypes.number.isRequired,
  displayAsNotification: PropTypes.bool,
};

OrderFee.defaultProps = {
  displayAsNotification: true,
};

export default OrderFee;
