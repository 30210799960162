import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { getContrastingColor }  from './utils.js';

function Badge(props) {
  const badgeTextColor = props.secondaryColor ? getContrastingColor(props.number > 0 ? props.secondaryColor : "#FFFFFF") : "";

  if(props.show) {
    if (props.number > 0) {
      return (
          <div className={classNames("h3 w3 fr mal tc circle circle-text f-18 bg--secondary badge " + badgeTextColor)} >
            {props.number}
          </div>
      );
    } else {
      return (
        <div className="fr mtm tc color-white f-40">
          <i className="fa fa-angle-right fa-sm icon-next no-text"></i>
        </div>
      );
    }
  } else {
    return <div style={{ display: 'none' }}></div>;
  }
}

Badge.propTypes = {
  number: PropTypes.number.isRequired,
  show: PropTypes.bool,
  secondaryColor: PropTypes.string,
};

export default Badge;
