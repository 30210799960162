import PropTypes from 'prop-types';
import {zeroPad} from "react-countdown";
import {InfoBadge} from "./EventHeading.jsx";
import React from 'react';
import classNames from 'classnames';

function CountdownLayout(props) {

    const Completionist = () =>
      <InfoBadge
        active={ true }
        hidden={ false }
        onClick={function() { window.location.reload(true)
        }}>
        {props.i18n('ticketSale.select.validationMessages.buyNowShort')}
      </InfoBadge>;

    if (props.completed) {
        // Render a complete rendered state
        return <Completionist />;
    } else {
        // Render a countdown
        return (
          <div
            id={ 'countdown' }
            className={ classNames('cdw fr pmd-7 default-pointer', {
              hidden: !props.isBeforeSaleStart || props.soldOut,
              'color-white': props.color === 'white',
              'color-black': props.color === 'black'
            })}>
            <div className={classNames('tc')}>
              {props.i18n('ticketSale.countdown.saleStart')}
            </div>
            <span className={classNames('kilo tc')}>{zeroPad(props.days)}:{zeroPad(props.hours)}:{zeroPad(props.minutes)}:{zeroPad(props.seconds)}</span>
            <div className={classNames('dt')}>
              <div className={classNames('f3 dtc cd')}>{props.i18n('ticketSale.countdown.daysShort')}</div>
              <div className={classNames('f3 dtc ch')}>{props.i18n('ticketSale.countdown.hoursShort')}</div>
              <div className={classNames('f3 dtc cm')}>{props.i18n('ticketSale.countdown.minutesShort')}</div>
              <div className={classNames('f3 dtc cs')}>{props.i18n('ticketSale.countdown.secondsShort')}</div>
            </div>
        </div>);
    }
}

CountdownLayout.propTypes = {
    isBeforeSaleStart: PropTypes.bool.isRequired,
    color: PropTypes.string.isRequired,
    soldOut: PropTypes.bool,
    completed: PropTypes.bool.isRequired,
    days: PropTypes.number.isRequired,
    hours: PropTypes.number.isRequired,
    minutes: PropTypes.number.isRequired,
    seconds: PropTypes.number.isRequired,
    i18n: PropTypes.func.isRequired
};

export default CountdownLayout;
