import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

function ErrorAlert(props) {
  return (
    <div className={classNames('bg-red pvm phl error-box-flex', { hidden: !props.show })}
         role="alert">
      <div className="circle bg-white f2 fw7 color-red symmetric mvm">!</div>
      <div className="color-white error-message-align" dangerouslySetInnerHTML={{__html: props.message}}></div>
    </div>
  );
}

ErrorAlert.propTypes = {
  message: PropTypes.string,
  show: PropTypes.bool,
};

export default ErrorAlert;
