import PropTypes from 'prop-types';
import React from 'react';
import Border from './Border.jsx';
import classNames from 'classnames';

function Rectangle(props) {
  const className = classNames('rect--responsive cf phl pbl mw-col ', props.color, props.topCSSBorder, props.classNames,
      { ptl: props.top, ptxl: !props.top });
  return (
   <div className={className}>
    <div className="rect--content--responsive">
      {props.children}
    </div>
     <Border color={props.bottomBorderColor} show={props.showBottomSVGBorder} top={props.showAllReservedText ? "2.35rem" : "0.7rem"}/>
   </div>
);
}

Rectangle.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  top: PropTypes.bool,
  topCSSBorder: PropTypes.string,
  bottomBorderColor: PropTypes.string,
  showBottomSVGBorder: PropTypes.bool,
  classNames: PropTypes.array,
  showAllReservedText: PropTypes.bool
};

export default Rectangle;
